import React from 'react';
// import axios from 'axios';
import { AuthConsumer } from '../utils/AuthContext';
import { ErrorMessage } from 'formik';
import Tag from '../general/tag'

const Login = (props) => {
  const { history } = props;

  return (
    <AuthConsumer>
      {({ login, errorMessage }) => (
        <div className="container">
          <section className="section">
          <form onSubmit={(e) => login(e, history)}>
            <div className="field">
              <label className="label">Email</label>
              <div className="control">
                <input className="input" type="email" placeholder="Email" id="login-email" />
              </div>
            </div>

            <div className="field">
              <label className="label">Password</label>
              <div className="control">
                <input className="input" type="password" placeholder="Password" id="login-password" />
              </div>
            </div>

            <div className="field">
              <div className="control">
                <input className="button is-primary" type="submit" value="Login"/>
              </div>
            </div>

            {errorMessage ? <Tag type="danger">
              <p>{errorMessage}</p>
            </Tag> : ""}

          </form>
        </section>
        </div>
      )}
    </AuthConsumer>
  )
}

export default Login;
