import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

import Table from '../general/table';
import ActionBar from '../general/actionBar';
import Input from '../general/input';
import Dropdown from '../general/dropdown';
import TitleBanner from '../general/titleBanner';

import SearchIcon from 'assets/icons8-search.svg';
import CatIllustration from 'assets/cat-illustration.svg';
import DogIllustration from 'assets/dog-illustration.svg';


const PetSelect = (props) => {

  const [pets, setPets] = useState();
  const [entriesNo, setEntriesNo] = useState(50);
  const [filterStr, setFilterStr] = useState('');

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL+'pets', {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    .then((res) => {
      console.log(res.data.data);
      setPets(res.data.data);
    })
    .catch((err) => {
      console.log(err);
    });
  }, []);

  const selectRow = (e) => {
    console.log(e.target);
    e.target.closest('tr').classList.toggle('is-selected');
  }

  return (
    <div>
      <ActionBar
        leftSide={
        <div className="field has-addons">
          <div className="control">
            <a className="button is-static">
              <img src={SearchIcon} height="16" className="is-icon is-16"></img>
            </a>
          </div>
          <div className="control is-expanded">
            <input className="input" type="text" placeholder="Pet name or tag number" onChange={(e) => setFilterStr(e.target.value)} />
          </div>
        </div> }
      />
      <section className="section">
        <div className="columns">
          <div className="column is-12">
            <TitleBanner
              leftImg={CatIllustration}
              rightImg={DogIllustration}
              title="Pets"
              subtitle={(pets?pets.length:"0") + " pets found"}
            />
          </div>
        </div>
      </section>

      <section className="section">
        <div className="columns">
          <div className="column is-12">
            <Table thTitles={["Name", "Type", "Breed",  "Color", "Tag #", "Date of birth", "Email"]}>
              {pets?pets
                .filter((pet) => {
                  if (JSON.stringify(pet).toLowerCase().includes(filterStr.toLowerCase())) {
                    return pet;
                  }
                })
                .slice(0, entriesNo)
                .map((pet, index) => {
                  return (
                    <tr key={index}>
                      <td nowrap="true" >
                        <input type="checkbox" onChange={(e) => selectRow(e)}></input>
                      </td>
                      <td nowrap="true">
                        <Link to={"/rabies-existing/"+pet.id}>
                          {pet.name}
                        </Link>
                      </td>
                      <td nowrap="true">{pet.type}</td>
                      {/* <td nowrap="true">{pet.gender}</td> */}
                      <td nowrap="true">{pet.breed}</td>
                      {/* <td nowrap="true">{pet.hair}</td> */}
                      <td nowrap="true">{pet.color}</td>
                      <td nowrap="true">
                        {pet.tag_number ? 
                          <p className="has-text-success">{pet.tag_number}</p> : 
                          <p className="has-text-danger">No tag</p>
                      }</td>
                      <td nowrap="true">{pet.dob}</td>
                      <td nowrap="true">{pet.email}</td>
                    </tr>
                  )
                }) : 
                <tr>
                  <td nowrap="true">
                  </td>
                  <td nowrap="true">
                    <progress className="progress is-small" max="100">15%</progress>
                  </td>
                </tr>
              }
            </Table>
            <button className="button is-primary" onClick={() => setEntriesNo(entriesNo + 50)}>Load more...</button>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PetSelect;
