import React from 'react';
import { AuthConsumer } from '../utils/AuthContext';
import Logo from '../general/logo';
import { Link } from 'react-router-dom';

import './nav.scss';

import VetIcon from 'assets/icons8-veterinarian.svg';


const ExternalNav = (props) => {

  return (
    <AuthConsumer>
      {({ isAuth, login, logout, user }) => (
        <div>
          <aside className="menu">
            <div className="navbar-brand">
              <Link className="navbar-item" to="/user">
                <Logo />
              </Link>
            </div>

            <ul className="menu-list">
              <p className="menu-subtitle">
                <img src={VetIcon} height="40" alt="Licenses"></img>
                Vet portal
              </p>
              {!isAuth?
                <li>
                  <Link to="/request-access">Request access</Link>
                </li>
                : ""
              }
             
              {isAuth?
                <li>
                  <Link to="/rabies-new">Certificate for a new pet</Link>
                </li>
                : ""
              }

              {isAuth?
                <li>
                  <Link to="/rabies-existing">Certificate for an existing pet</Link>
                </li>
                : ""
              }
            </ul>

            <ul className="menu-list">
              <li>
                {isAuth?
                  <a onClick={(e) => logout(e)}>Logout</a>
                :<Link to="/login">Login</Link>}
              </li>
            </ul>
          </aside>
        </div>
      )}
    </AuthConsumer>
  );
}

export default ExternalNav;
