import React from 'react';
import { Link } from 'react-router-dom';
import WelcomeIllustration from 'assets/welcome-illustration.svg'
import FAQIllustration from 'assets/faq-illustration.svg'

import Faqs from './faqs';

const DashboardDefault = (props) => {


  return (
    <div>
      <section className="section">
        <div className="columns is-multiline is-vcentered">
          <div className="column is-3">
            <img src={WelcomeIllustration} alt="Person greeting dog" />
          </div>
          <div className="column is-7">
            <p className="is-size-4 has-text-weight-bold">Welcome to the RT Vet portal!</p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default DashboardDefault;
