import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Input from '../general/input';
import '../license/license-form.sass';

import termsCat from 'assets/terms-cat.png';
import termsDog from 'assets/terms-dog.png';


const RequestAccess = () => {

  const [step, setStep] = useState(1);

  const sendRequest = (e) => {
    const target = e.target;
    target.classList.add('is-loading');

    setTimeout(function() {
      setStep(2);
      target.classList.remove('is-loading');
    }, 1000)
  }

  return (
    <section className="section">
      {step === 1 ? 
        <div>
          <div className="columns">
            <div className="column is-12">
              <div className="card terms-card" style={{marginBottom: "24px"}}>
                  <div className="card-header has-text-centered">
                    <img src={termsDog} alt="" />
                    <div style={{margin: "auto"}}>
                      <p className="has-text-weight-bold is-size-4">Request access to our Vet Portal!</p>
                      <p>This will allow you to generate and send rabies certificates easily, directly to the county administration that your customers belong to.</p>
                    </div>
                    <img src={termsCat} alt="" />
                  </div>
                </div>
            </div>
          </div>
          <div className="columns is-multiline">
            <div className="column is-6">
              <Input 
                label="Name"
                placeholder="Michael Scott"
              />
            </div>
            <div className="column is-6">
              <Input 
                label="City"
                placeholder="Arlington"
              />
            </div>
            <div className="column is-6">
              <Input 
                label="Address"
                placeholder="201 N Stuart Street"
              />
            </div>
            <div className="column is-12">
              <button className="button is-primary" onClick={(e) => sendRequest(e)}>Request access</button>
            </div>
          
          </div>
        </div> : ""
      }

      {step === 2 ? 
        <div className="columns">
          <div className="column is-12 content">
            <div className="card terms-card" style={{marginBottom: "24px"}}>
              <div className="card-header has-text-centered">
                <img src={termsDog} alt="" />
                <p className="has-text-weight-bold is-size-4">Request sent!</p>
                <img src={termsCat} alt="" />
              </div>
            </div>
            <p>
              <svg className="inline-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M14.72 8.79l-4.29 4.3-1.65-1.65a1 1 0 1 0-1.41 1.41l2.35 2.36a1 1 0 0 0 .71.29 1 1 0 0 0 .7-.29l5-5a1 1 0 0 0 0-1.42 1 1 0 0 0-1.41 0zM12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z"/>
              </svg>
              Your request was successfully sent! We will analyze it and get back to you in 24 hours!</p>
          </div>
        </div> : ""
      }
    </section>
  );
}

export default RequestAccess;
