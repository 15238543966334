import React from 'react';
import { Link } from 'react-router-dom';
// import ProtectedRoute from '../utils/ProtectedRoute';
import Logo from '../general/logo';
import LicensesIcon from 'assets/icons8-renew.svg';
import PetFriendlyIcon from 'assets/icons8-dog_park.svg';
import ScanIcon from 'assets/icons8-portrait_mode_scanning.svg';
import PawIcon from 'assets/icons8-cat_footprint.svg';

import ExternalNav from '../app/externalNav';

const ExternalLayout = ({children}) => {

  const toggleSidebar = () => {
    // console.log(document.querySelector('.is-sidebar'));
    document.querySelector('.is-sidebar').classList.toggle('is-hidden');
    document.querySelector('.is-content').classList.toggle('is-12-desktop');
  }
  const toggleMenu = () => {
    document.querySelector(".navbar-burger").classList.toggle("is-active");
    document.querySelector(".navbar-menu").classList.toggle("is-active");
  }

  return(
    <div>
      <nav className="navbar is-hidden-desktop" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <Link className="navbar-item" to="/user">
              <Logo />
            </Link>

            <p role="button" className="navbar-burger burger" onClick={() => toggleMenu()}>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </p>
          </div>

          <div id="navbarBasicExample" className="navbar-menu">
            <div className="navbar-start is-hidden-desktop">
              <ul className="menu-list">
              <p className="menu-subtitle">
                <img src={PawIcon} height="40" alt="Licenses"></img>
                Profile
              </p>
              <li>
                <Link to="/user">Dashboard</Link>
              </li>
              <li>
                <Link to="/user/pets">My pets</Link>
              </li>
              <li>
                <Link to="/user/profile">My profile</Link>
              </li>
            </ul>

            <ul className="menu-list">
              <p className="menu-subtitle">
                <img src={LicensesIcon} height="40" alt="Licenses"></img>
                Licenses
              </p>
              <li>
                <Link to="/user/license">New license</Link>
              </li>
              <li>
                <Link to="/user">Renew license</Link>
              </li>
            </ul>

            <ul className="menu-list">
              <p className="menu-subtitle">
                <img src={ScanIcon} height="40" alt="Scan a tag"></img>
                Tags
              </p>
              <li>
                <Link to="/user/scan">Scan tag</Link>
              </li>
            </ul>

            <ul className="menu-list">
              <p className="menu-subtitle">
                <img src={PetFriendlyIcon} height="40" alt="Scan a tag"></img>
                Pet friendly places
              </p>
              <li>
                <Link to="/user/restaurants">Restaurants</Link>
                <Link to="/user/hotels">Hotels</Link>
                <Link to="/user/services">Services</Link>
              </li>
            </ul>
              {/* {isAuth?
                <button className="button is-text" onClick={(e) => logout(e)}>Logout</button>
              :""} */}
            </div>
            {/* <div className="navbar-end">
              <div className="navbar-item">
                
              </div>
            </div> */}
          </div>
        </nav>
      <div className="columns is-paddingless is-marginless">
        <div className="column is-3 is-sidebar is-hidden-touch is-paddingless">
          <ExternalNav toggleSidebar={() => toggleSidebar()}/>
        </div>
          <div className="column is-content is-9-desktop is-12-touch is-paddingless" style={{paddingLeft: "0px"}}>
            {children}
          </div>
      </div>
    </div>
  )
}
export default ExternalLayout;
