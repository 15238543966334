import React, {useState, useEffect} from 'react';
import axios from 'axios';

const AuthContext = React.createContext();

const AuthProvider = (props) => {
  const [isAuth, setIsAuth] = useState(false);

  const [user, setUser] = useState();

  const [county, setCounty] = useState();

  const [errorMessage, setErrorMessage] = useState(null);


  // console.log(process.env);
  // console.log(process.env.REACT_APP_API_URL);


  useEffect(() => {
    const localUser = localStorage.getItem('user');
    if (localUser) {
      setIsAuth(true);
      setUser(JSON.parse(localUser));
      // console.log(user);
    }
  }, [])

  const login = (e, history) => {
    e.preventDefault();
    const creds = 'email=' + document.getElementById('login-email').value + '&password=' + document.getElementById('login-password').value;

    axios.post(process.env.REACT_APP_API_URL + 'authenticate?' + creds)
    .then(function(res) {
      if (res.status) {
        if (res.data.token) {
          console.log('User profile', res);
          console.log('User token', res.data.token);
          localStorage.setItem('token', res.data.token);
          localStorage.setItem('user', JSON.stringify(res.data.user));
          setUser(res.data.user);
          setIsAuth(true);
          setErrorMessage();
          history.push('/');
        } else {
          setErrorMessage(res.data.message);
        }
      } else {
        console.log(res);
      }


    })
    .catch(function (error) {
      console.log(error);
    });
  }

  const logout = (e, history) => {
    e.preventDefault();
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    setIsAuth(false)
    setUser(null);
    // history.push('/');
  }

  return (
    <AuthContext.Provider
      value={{
        isAuth: isAuth,
        login: login,
        logout: logout,
        user: user,
        county: window.location.hostname.split('county.')[0].charAt(0).toUpperCase() + window.location.hostname.split('county.')[0].slice(1),
        errorMessage: errorMessage
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}

const AuthConsumer = AuthContext.Consumer

export { AuthProvider, AuthConsumer }
