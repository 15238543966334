import React from 'react';
import { Link } from 'react-router-dom';
import WelcomeIllustration from 'assets/welcome-illustration.svg'

const DashboardLogged = (props) => {

  return (
    <div>
      <section className="section">
        <div className="columns is-multiline is-vcentered">
          <div className="column is-3">
            <img src={WelcomeIllustration} alt="Person greeting dog" />
          </div>
          <div className="column is-7">
            <p className="is-size-4 has-text-weight-bold">Welcome to the RT Vet portal!</p>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="columns is-multiline is-vcentered">
          <div className="column is-7">
            <p className="">Use this portal to automatically generate and send rabies certificates directly to the county administration.</p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default DashboardLogged;
