import React from 'react';
import '../../stylesheets/input.scss';

const Input = (props) => {

  return (
    <div>
      <label className="label">{props.label}</label>
      <div className="field has-addons">
        <div className="control is-expanded">
          <input className="input" type="text" placeholder={props.placeholder} defaultValue={props.value} id={props.id}/>
        </div>
        {props.hasAddon?
          <div className="control">
            {props.addonContent}
          </div>:""
        }
      </div>
    </div>
  )
}

export default Input;
