import React from 'react';
import {  AuthConsumer } from '../utils/AuthContext';
import LostBanner from 'assets/lost-banner.png'
import './dashboard.scss'

import DashboardDefault from './dashboardDefault';
import DashboardLogged from './dashboardLogged';

const Dashboard = (props) => {

  return (
    <AuthConsumer>
      {({ isAuth, login, logout, user, county }) => (
        <div>
          {isAuth ?
            <DashboardLogged {...props} /> : <DashboardDefault {...props} />
          }
        </div>
      )}
    </AuthConsumer>
  );
}

export default Dashboard;
