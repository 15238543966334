import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  Redirect
} from 'react-router-dom';
import './App.scss';

import { AuthProvider, AuthConsumer } from '../utils/AuthContext';
import ProtectedRoute from '../utils/ProtectedRoute';
import AdminRoute from '../utils/AdminRoute';
import ScrollToTop from '../utils/scrollToTop';

// External
import ExternalLayout from '../layouts/external';
import Dashboard from '../dashboard';

// Vet portal
import SendCert from '../vets/sendCert';
import PetSelect from '../vets/petSelect';
import RequestAccess from '../vets/requestAccess';


import Login from '../account/login';

const App = (props) => {

  return (
    <Router>
      <ScrollToTop />
      <AuthProvider>
        
        <AuthConsumer>
          {({ isAuth, login, logout, user, county }) => (
            <Switch>
              {/* External Routes */}
              <RouteWrapper exact path="/" component={(props) => <Dashboard county={county}/>} layout={ExternalLayout} />
              <RouteWrapper path="/login" component={Login} layout={ExternalLayout} />
              <RouteWrapper path="/request-access" component={RequestAccess} layout={ExternalLayout} />
              <AdminRoute path="/rabies-new" component={SendCert} layout={ExternalLayout} />
              <AdminRoute exact path="/rabies-existing" component={PetSelect} layout={ExternalLayout} />
              <AdminRoute path="/rabies-existing/:id" component={SendCert} layout={ExternalLayout} />

              <Route render={(props)=> <Redirect to="/"/>} />
            </Switch>
          )}
        </AuthConsumer>
      <div>
      </div>
      </AuthProvider>
    </Router>
  );
}

const RouteWrapper = ({
  component: Component,
  layout: Layout,
  ...rest
}) => {
  return (
    <Route {...rest} render={(props) =>
      <Layout {...props}>
        <Component {...props} />
      </Layout>
    } />
  );
}


export default App;
